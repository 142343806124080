var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.deposit,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "show-expand": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.status))]
                },
              },
              {
                key: "item.amount_proposed",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          `${
                            item.proposed_amount
                              ? item.proposed_amount.toLocaleString("ru-RU")
                              : ""
                          }`
                        ) +
                        " " +
                        _vm._s(item.cryptocurrency.title) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.network",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.deposit_wallet
                            ? item.deposit_wallet.cryptonetwork.title
                            : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.description",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.deposit_wallet.description))]
                },
              },
              {
                key: "item.walletid",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.deposit_wallet ? item.deposit_wallet.id : "--"
                        )
                    ),
                  ]
                },
              },
              {
                key: "item.created_at",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.created_at
                            ? new Date(
                                Date.parse(item.created_at)
                              ).toLocaleString()
                            : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.check",
                fn: function ({ item }) {
                  return [
                    item.proof
                      ? _c("a", { attrs: { href: item.proof } }, [
                          _vm._v("Чек"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c("NumberEditDialog", {
                      attrs: {
                        caption: "Пополнить",
                        title:
                          "Нужно ввести сумму, которую перевёл пользователь",
                        label: "Сумма",
                        tag: item,
                        value: "0",
                      },
                      on: { ok: _vm.success },
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "red",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.cancel(item)
                          },
                        },
                      },
                      [_vm._v("Отклонить")]
                    ),
                  ]
                },
              },
              {
                key: "item.nickname",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.user ? item.user.nickname : "")),
                  ]
                },
              },
              {
                key: "item.userid",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.user ? item.user.id : ""))]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c("td", { attrs: { colspan: headers.length - 2 } }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Валюта: ${item.deposit_wallet.cryptonetwork.title}`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.balance_after
                                ? `Баланс после: ${item.balance_after.toLocaleString(
                                    "ru-RU",
                                    { style: "currency", currency: "USD" }
                                  )}`
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.balance_before
                                ? `Баланс до: ${item.balance_before.toLocaleString(
                                    "ru-RU",
                                    { style: "currency", currency: "USD" }
                                  )}`
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `ID пользователя: ${
                                item.user ? item.user.id : ""
                              }`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Кошелек зачисления: ${
                                item.deposit_wallet
                                  ? item.deposit_wallet.wallet
                                  : "--"
                              }`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Время изменения: ${
                                item.created_at
                                  ? new Date(
                                      Date.parse(item.created_at)
                                    ).toLocaleString()
                                  : ""
                              }`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Депозиты")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }